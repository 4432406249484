import { ArrowDropDown } from "@mui/icons-material"
import { Box, Button, useMediaQuery } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Theme } from "@mui/material/styles"
import { styled } from "@mui/system"
import { AuthDialog, NAVIGATION_HEIGHT } from "app-constants/constants"
import { createURL_SSR, ROUTE } from "app-constants/routing"
import PoweredByVisitdaysLogo from "components/PoweredByVisitdaysLogo"
import { SponsorsList } from "components/SponsorsList"
import { ActivityIndicator } from "components/activity-indicator"
import ConventionsVerticalList from "components/conventions/ConventionsVerticalList"
import NoConventionsYetFallback from "components/conventions/NoConventionsYetFallback"
import ConventionsFilterPanel, {
  CONVENTIONS_FILTER_PANEL_HEIGHT,
} from "components/conventions/filters/ConventionsFilterPanel"
import ConventionsFilterPanelDialog from "components/conventions/filters/ConventionsFilterPanelDialog"
import MacroLayout from "components/layouts/Macro"
import useConventionFilters from "hooks/conventions/useConventionFilters"
import useAuthDialog from "hooks/useAuthDialog"
import { useBoolean } from "hooks/useBoolean"
import useSearchParams from "hooks/useSearchParams"
import buildServerSidePage from "lib/build-server-side-page"
import buildServerSideProps from "lib/build-server-side-props"
import getExpoCopyText from "lib/getExpoCopyText"
import applyCache from "lib/mw/apply-cache"
import applyCurrentServerContext from "lib/mw/apply-current-server-context"
import applyNextSeo from "lib/mw/apply-next-seo"
import applyRequiredProps from "lib/mw/apply-required-props"
import applyTitle from "lib/mw/apply-title"

import Convention from "models/convention-api/v1/Convention"
import Sponsor from "models/convention-api/v1/Sponsor"
import SponsorTier from "models/convention-api/v1/SponsorTier"
import pluralize from "pluralize"
import React from "react"
import useAsyncEffect from "use-async-effect"
import { get30SecondsIntervalISOSDate, pxToRem } from "utils/helpers"
import tryToGetAppLogo from "utils/tryToGetAppLogo"
import useCurrent, { Current } from "lib/use-current"

// const useCurrent = dynamic<Promise<currentContextProps>>(() => import("lib/use-current").then(current => current.default), { 
//   ssr: false,
// })


export const getServerSideProps = buildServerSideProps(
  applyCache(),
  applyCurrentServerContext(),
  [
    Convention.applyAll("conventions", {
      filter: {
        upcoming: "true",
      },
      includes: [
        "convention_registration_capacity",
        "convention_ticket_types",
        "convention_booleans",
        "convention_session_count",
      ],
    }),
    Convention.applyOne("conventionAvailableForRegistration", {
      filter: {
        upcoming: "true",
        registration_open: "true",
        registration_slots_available: "true",
        // temp fix for avoiding cache to see the latest registration data
        cache_burst: get30SecondsIntervalISOSDate(),
      },
    }),
    Sponsor.applyAll("sponsors"),
    SponsorTier.applyAll("sponsorTiers"),
  ],
  applyRequiredProps("conventions", "sponsorTiers", "sponsors"),
  applyTitle((context) => getExpoCopyText(context.props.current).plural),
  applyNextSeo((context) => ({
    openGraph: {
      title: context.props.current?.provider?.name,
      url: createURL_SSR(context, { path: ROUTE.CONVENTIONS }),
      type: "website",
      images: [tryToGetAppLogo(context.props.current)],
    },
  })),
)

interface ConventionsPageProps {
  conventions: Convention[]
  conventionAvailableForRegistration: Convention
  sponsorTiers: SponsorTier[]
  sponsors: Sponsor[]
  current: Current
}

export default buildServerSidePage<ConventionsPageProps>(ConventionsPage)

function ConventionsPage(props: ConventionsPageProps) {
  const hasConventions = props.conventions.length > 0 || !!props.conventionAvailableForRegistration

  const current = useCurrent()

  const searchParams = useSearchParams()
  const authDialog = useAuthDialog()

  useAsyncEffect(async () => {
    if (!searchParams.get("openSignInDialog")) return
    await searchParams.delete("openSignInDialog")

    if (current?.user) return
    authDialog.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})
  }, [searchParams.get("openSignInDialog")])

  useAsyncEffect(async () => {
    if (!searchParams.get("openSignUpDialog")) return
    await searchParams.delete("openSignUpDialog")

    if (current?.user) return
    authDialog.set(AuthDialog.SIGN_UP)
  }, [searchParams.get("openSignUpDialog")])

  if (!hasConventions) return <NoConventionsYetFallback />

  return <ConventionsPageWithConventions {...props} />
}

function ConventionsPageWithConventions(props: ConventionsPageProps) {
  const current = useCurrent()

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"))

  const userConventionRegistrantTypeId = current?.user?.convention_registrant_type_id

  const conventionsFilterPanelDialogOpen = useBoolean(false)
  const conventionFilters = useConventionFilters()

  const conventions = Convention.useAll({
    filter: {
      ...conventionFilters.filter,
      upcoming: "true",
      ...(userConventionRegistrantTypeId && {
        convention_registrant_type_id: [userConventionRegistrantTypeId, "all"],
      }),
    },
    includes: [
      "convention_registration_capacity",
      "convention_ticket_types",
      "convention_booleans",
      "convention_session_count",
    ],
    // @ts-ignore
    fallbackData: current?.user || conventionFilters.isApplied ? undefined : props.conventions,
  })

  const isLoading = (!conventions.data && conventions.isValidating) || conventions.isValidating
  const title = current ? getExpoCopyText(current).plural : ""
  const total = conventions.data?.length ?? 0
  const titleByCount = current ? getExpoCopyText(current)?.byCount(total).toLowerCase() : ""

  const getConventionsCountText = () => {
    if (isLoading) return <></>
    if (conventionFilters.isFilterApplied && conventions.data?.length === 0) {
      return <>There are no events matching your criteria.</>
    }

    if (conventionFilters.isFilterApplied)
      return (
        <>
          There {pluralize("is", total)} {total} {titleByCount} matching your criteria.
        </>
      )

    return (
      <>
        There {pluralize("is", total)} {total} upcoming {titleByCount}.
      </>
    )
  }

  return (
    <>
      {!isDesktop && (
        <ConventionsFilterPanelDialog
          open={conventionsFilterPanelDialogOpen.value}
          onClose={conventionsFilterPanelDialogOpen.off}
          filters={conventionFilters}
        />
      )}
      <MacroLayout>
        <_Container>
          <_LeftContainer>
            <ConventionsFilterPanel filters={conventionFilters} />
          </_LeftContainer>
          <_RightContainer>
            <_ConventionHeader>
              <Typography variant="header1" color="gray.darkest">
                {`Upcoming ${title}`}
              </Typography>
              {!isLoading && (
                <Typography variant="paragraphRegular" color="gray.darkest" marginTop="40px">
                  {getConventionsCountText()}
                </Typography>
              )}
              <_FilterButtonContainer>
                <Button
                  color="dark"
                  variant="text"
                  onClick={conventionsFilterPanelDialogOpen.on}
                  endIcon={<ArrowDropDown />}>
                  FILTER
                </Button>
              </_FilterButtonContainer>
            </_ConventionHeader>

            {isLoading && <ActivityIndicator />}

            {!isLoading && <ConventionsVerticalList conventions={conventions.data ?? []} />}
          </_RightContainer>
        </_Container>

        <_BottomContainer>
          <SponsorsList sponsorTiers={props.sponsorTiers} sponsors={props.sponsors} />
          <PoweredByVisitdaysLogo />
        </_BottomContainer>
      </MacroLayout>
    </>
  )
}

const _Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",

  width: "100%",
  height: "100%",
  paddingBottom: pxToRem(64),

  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: `${pxToRem(300)} 1fr`,
    gap: pxToRem(100),
  },
})) as typeof Box

const _LeftContainer = styled(Box)(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("lg")]: {
    display: "block",
    // make the filter panel sticky
    "& > form": {
      position: "sticky",
      top: pxToRem(64),
      overflowY: "auto",
      height: `calc(100vh - ${NAVIGATION_HEIGHT}px - ${pxToRem(30)})`,
      maxHeight: CONVENTIONS_FILTER_PANEL_HEIGHT,
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
})) as typeof Box

const _RightContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: pxToRem(956),
  },
})) as typeof Box

const _BottomContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexFlow: "column nowrap",

  width: "100%",
  height: "100%",
}) as typeof Box

const _ConventionHeader = styled(Box)(({}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: pxToRem(40),
}))

const _FilterButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: pxToRem(40),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
})) as typeof Box
